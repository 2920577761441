@import "../../../main";


.carShowContainer{
    flex-grow: 1;
    background-color: $bg_color;
}
.carInfromationHeader{
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 24px;
    background-color: #F5F7FA;
    box-shadow: 0px 4px 20px rgba(182, 191, 207, 0.4);
}
.carShowBody{
    padding: 32px 24px 0px 40px;
    box-sizing: border-box;
}
.carShowBackButton{
    margin-top: 35px;
    margin-bottom: 22px;
    cursor: pointer;
}
.carShowNameContainer{
    display: flex;
    margin-top: 12px;
    margin-bottom: 31px;
    align-items: center;
}
.removeCarCard{
    padding: 16px 24px 24px 24px;
    background-color: #F8E0E0;
    margin-bottom: 24px;
}