.carCard{
    width: 100%;
    box-sizing: border-box;
}
.conditionContainer{
    width: 90px;
    height: 36px ;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carInformationCard{
    display: flex;
    flex-direction: column;
    background: #F5F7FA;
    width: 100%;
    border: 1px solid #CAD0DB;
    box-shadow: 0px 10px 20px -5px rgba(182, 191, 207, 0.3);
    margin-bottom: 24px;
}
.carCardFooter{
    display: flex;
    align-items: center;
    margin-top: auto;
    border-top: 1px solid #CAD0DB;
    height: 52px;
    padding: 0px 16px 0px 23px;
}
.carCardBody{
    padding: 0px 50px;
    margin-top: 16px;
    margin-bottom: 24px;
}