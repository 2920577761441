@import "../../../main";
.carsListContainer{
    display: inline-flex;
    flex-direction: column;
    background-color: $bg_color;
    flex-grow: 1;
    height: 100%;
}
.carsListHeader{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 40px;
    box-sizing: border-box;
    margin-top: 30px;
    margin-bottom: 20px;
}
.carsListSearchContainer{
    display: flex;
    background-color: white;
    border: solid 1px #E4E7ED;
    width: 100%;
    padding: 4px 4px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    &:focus-within{
        border: 1px solid #2A303B;
        box-shadow: 0px 4px 16px rgba(182, 191, 207, 0.5);
    }
}
.carsListTabNumber{
    display: inline-block;
    background-color: #E4E7ED;
    padding: 0px 4px;
    margin-left: 2px;
}
.carsListFiltersContainer{
    display: flex;
    align-items: center;
}
.carsListCarsContainer{
    width: 100%;
    position: relative;
    display: inline-flex;
    flex-direction: column;
}
.carsListSort{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    padding-left: 12px;
}