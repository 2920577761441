body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, html, #root{
  width: 100%;
  height: 100%;
  display: inline-table;
  background-color: #F5F7FA;
}

@media print { 
  .page-break {
    margin-top: 1rem;
    display: block;
    /* page-break-before: always; */
        page-break-after: always;
  }
  html, body {
     height: initial !important; 
     overflow: initial !important; 
     background-color: white;
     -webkit-print-color-adjust: exact;
    }
  }
  @page {
    size: A4;
    margin: 5mm;
  }
  /* @media all {
    .page-break {
       display: none;
    }
  } */
  table {page-break-before: always !important; }
tr{page-break-inside: avoid !important; 
page-break-after: auto !important;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}